body,
.mainBgC {
  /* background: #08000f; */
  background: #0b0e13;
}

body::-webkit-scrollbar {
  width: 16px;
  display: none;
}

body::-webkit-scrollbar-track {
  background-color: rgba(51, 50, 50, 0.58);
  border-radius: 100px;
}

body::-webkit-scrollbar-thumb {
  background-color: #d8ff12;
  border-radius: 100px;
}

.navbarBg {
  background: rgba(51, 50, 50, 0.58);
}
.navbar {
  height: 4rem;
  width: -webkit-fill-available;
  background: rgba(51, 50, 50, 0.58);
  scrollbar-width: none;
}

/* .logoHome {
  position: relative;
  width: 150px;
}

.logoHome svg {
  position: absolute;
  top: -2.4rem;
} */

.beena {
  font-family: "Abril Fatface", serif;
  color: #ffd600;
}

.plastics {
  font-family: ShadowsIntoLight-Regular, sans-serif;
  color: #ffffff;
}

.hero {
  /* font-size: 140px; */
  color: #ffffff;
  position: relative;
  /* width: 100vw; */
  height: 90vh;
  width: -webkit-fill-available;
  /* 
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./blurry-gradient-haikei.png"); */
}

.note {
  position: absolute;
}

#canvas {
  /* border: 1px solid black; */
  background-color: #00000000;
  width: 100%;
  height: 90vh;
  margin: auto;
}

.scrollbtn {
  position: absolute;
  bottom: 10%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #00000000;
  /* left: 48%; */
  /* font-size: 4rem; */
}

.aboutUsGradient {
  background-image: linear-gradient(#ffe7aa 0%, rgb(231 255 0) 100%);
  border-color: #d8ff12;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.scroller {
  overflow: hidden;
  display: flex;
  width: 100%;
  margin: 0 auto;
}
.scroller div {
  animation: slide 15s linear infinite;
}
@media only screen and (max-width: 767px) {
  @keyframes slide {
    0% {
      transform: translate3d(0, 0, 0);
    }

    10% {
      transform: translate3d(2px, 0, 0);
    }

    50% {
      transform: translate3d(-1000px, 0, 0);
      /* The image width */
    }

    100% {
      transform: translate3d(0, 0, 0);
      /* The image width */
    }
  }
}

@media only screen and (min-width: 992px) {
  @keyframes slide {
    0% {
      transform: translate3d(0, 0, 0);
    }

    10% {
      transform: translate3d(2px, 0, 0);
    }

    50% {
      transform: translate3d(-500px, 0, 0);
      /* The image width */
    }

    100% {
      transform: translate3d(0, 0, 0);
      /* The image width */
    }

    /* No changes for laptops in this example */
  }
}

.smallCTA,
html {
  scroll-behavior: smooth;
}

.largeCTA {
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url("./low-poly-grid-haikei.svg"); */

  /* font-family: 'Alumni Sans', sans-serif; */
}
.logoHome {
  position: relative;
  width: 200px;
}

.logoHome svg {
  position: absolute;
  top: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.logo-font {
  font-family: "didot", serif;
  font-weight: 200;
}

.logo-name {
  font-family: "didot", serif;
  font-weight: 500;
  letter-spacing: -4px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* text-shadow: #FC0 1px 0 10px; */
  /* color: white; */
}

.logoHome span {
  position: absolute;
  /* left: 60%; */
  width: 150px;
}

.beena {
  font-family: "Abril Fatface", serif;
  color: #ffd600;
}

.plastics {
  font-family: ShadowsIntoLight-Regular, sans-serif;
  color: #ffffff;
}

.timelineCardColor {
  background: #565387;
}

.ItemBg {
  background-image: url("../resources/prdImgs/prdbg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-clip: border-box;
}

.img-block {
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

.img-block:hover {
  transform: scale(1.23);
  box-shadow: 0 30px 35px rgba(0, 0, 0, 0.7);
  z-index: 5;
}
.img-block span {
  position: absolute;
  width: 80%;
  height: 100%;
  top: 0;
  left: -120%;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.5s;
  transform: skewX(10deg);
}

/* .img-block img {
  width: 100%;
} */

.img-block:hover span {
  left: 120%;
}

.DisplayHeight{
  height: auto;
}

.DisplayHeight::-webkit-scrollbar {
  width: 6px;
  /* display: none; */
}

.DisplayHeight::-webkit-scrollbar-track {
  background-color: rgba(51, 50, 50, 0.58);
  border-radius: 100px;
}

.DisplayHeight::-webkit-scrollbar-thumb {
  background-color:#facc15;
  border-radius: 100px;
}

@media (min-width: 1020px){
  .DisplayHeight{
    height: 500px;
  }
  
}
.text-gradient-to-r {
  /* This property ensures that the text color will transition as a gradient */
  background-clip: text;
  /* Use the same gradient colors as before */
  color: transparent;
  /* Create the gradient */
  background-image: linear-gradient(to left,#af79e9, #6a40aa);
}