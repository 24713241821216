@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@1,100&display=swap");
.note {
  font-size: 1.5rem;
  font-family: "Alumni Sans", sans-serif;
  position: absolute;
  top: 10%;
  left: 6%;
}
.noteFont {
  font-family: "Alumni Sans", sans-serif;
}

.text_1 {
  animation: text1;
}

.text_2 {
  animation: text2;
}

.text_3 {
  animation: text3;
}

.text_1,
.text_2,
.text_3 {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  animation-duration: 12s;
  animation-timing-function: steps(20, end);
  animation-iteration-count: infinite;
}

.text_1::after,
.text_2::after,
.text_3::after {
  content: "|";
  position: absolute;
  right: 0;
  animation: caret infinite;
  animation-duration: 0.5s;
  animation-timing-function: steps(1, end);
}

@keyframes text3 {
  0%,
  33%,
  76%,
  100% {
    width: 0;
  }

  86%,
  97% {
    width: 5.2em;
  }
}

@keyframes text2 {
  0%,
  33%,
  76%,
  100% {
    width: 0;
  }

  45%,
  60% {
    width: 9.4em;
  }
}

@keyframes text1 {
  0%,
  33%,
  76%,
  100% {
    width: 0;
  }
  10%,
  30% {
    width: 9.3em;
  }
}

@keyframes caret {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
