.boujee {
    --bg-size: 400%;
    --color-one: hsla(48, 99%, 68%, 0.49);
    --color-two: hsla(71, 56%, 57%, 0.49);
    --color-three: hsla(200, 73%, 66%, 0.434);
    background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-three),
      var(--color-one)
    
      

    ) 0 0 / var(--bg-size) 100%;
    color: transparent;
   
    animation: move-bg 7s infinite linear;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .boujee {
      animation: move-bg 7s linear infinite;
    }
  }
  
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }